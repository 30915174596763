#setup {
  background-color: #efefef;
  height: 100vh;
  width: 100vw;

  #headers {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 80px;
    width: 100%;

    #logo {
      height: 50px;
      width: 317px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  #user-actions-container {
    display: flex;
    flex-direction: row-reverse;
    height: 80px;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;

    #user-actions-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      margin-right: 10px;
    }
  }

  #user-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .user-action {
      background-color: black;
      border-color: black;
      border-radius: 10px;
      border-style: solid;
      border-width: 1px;
      color: white;
      display: flex;
      flex-direction: row;
      font-size: 20px;
      font-weight: bold;
      justify-content: space-around;
      padding: 10px;
      text-align: center;

      .user-action-header {
        font-size: 15px;
        font-weight: bold;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }

  #body {
    display: flex;
    flex-direction: column;
    height: calc(90% - 80px);
    justify-content: space-around;
    width: 100%;

    #form {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 5px lightgrey;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 auto;
      padding: 10px;
      width: 500px;

      #form-header {
        font-size: 3vh;
        font-weight: bold;
        margin-bottom: 2vh;
      }

      #note {
        background-color: rgba(0, 147, 255, 0.1);
        border-color: #D3EAFF;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px white;
        color: #00529E;
        font-weight: 500;
        margin-bottom: 2vh;
        padding: 2vh;

        &:hover {
          cursor: pointer;
        }
      }

      #inputs {
        width: 100%;

        .input-container {
          margin-bottom: 2vh;
          width: 100%;

          .input-header {
            font-weight: bold;
            margin-bottom: 5px;
          }

          .input-value {
            input {
              background-color: rgba(0, 147, 255, 0.05);
              border-color: lightgrey;
              border-style: solid;
              border-width: 0.5px;
              border-radius: 5px;
              box-shadow: 0px 0px 5px white;
              font-size: 2vh;
              padding: 1vh;
              width: calc(100% - 4vh);
            }
          }

          #select-tarlangs {
            border-radius: 10px;
            margin-bottom: 30px;
  
            #select-tarlangs-container {
              display: flex;
              flex-direction: row;
              width: 300px;
  
              #select-tarlangs-button {
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 0px 10px lightgrey;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                padding: 10px;
                width: 280px;
  
                .select-tarlangs-arrow {
                  height: 20px;
                  margin-left: 10px;
                  width: 20px;
                }
  
                &:hover {
                  box-shadow: 0px 0px 5px lightgrey;
                  cursor: pointer;
                }
              }
  
              .select-tarlangs-items {
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 0px 10px lightgrey;
                margin: 45px 0 0 0;
                overflow: hidden;
                position: absolute;
                z-index: 1;
                width: 300px;
  
                #search-input {
                  border-color: black;
                  border-radius: 10px;
                  border-style: solid;
                  border-width: 0.5px;
                  margin: 5px;
                  outline: none;
                  padding: 7px;
                  width: calc(100% - 24px);
                }
  
                #select-tarlangs-items-list {
                  height: 20vh;
                  overflow-y: scroll;
  
                  .select-tarlangs-item-unselect,
                  .select-tarlangs-item-selected {
                    display: flex;
                    flex-direction: row;
                    padding: 10px;
  
                    .select-tarlangs-item-point {
                      border-radius: 10px;
                      border-style: solid;
                      border-width: 0.5;
                      height: 8px;
                      margin: 5px 0;
                      width: 8px;
                    }
  
                    .select-tarlangs-item-icon {
                      height: 30px;
                      width: 30px;
                    }
  
                    .select-tarlangs-item-header {
                      font-weight: bold;
                      margin-left: 10px;
                    }
                  }

                  .select-tarlangs-item-unselect:hover {
                    background-color: lightgrey;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          #selected-langs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .selected-lang {
              background-color: white;
              border-radius: 10px;
              box-shadow: 0px 0px 5px lightgrey;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin: 5px;
              padding: 5px;

              .selected-lang-header {
                font-size: 15px;
              }

              .selected-lang-delete {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }

      #errormsg {
        color: red;
        font-size: 20px;
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
      }

      #form-submit {
        background-color: rgb(0, 130, 199);
        border-color: darkblue;
        border-radius: 5px;
        border-style: solid;
        border-width: 0.5px;
        color: white;
        font-weight: bold;
        padding: 10px;
        text-align: center;
        width: 80px;

        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}