#header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 80px;
  width: 100%;

  #header-title {
    font-family: videodub-font;
    font-size: 30px;
    text-align: center;

    span {
      color: #F15A24;
    }
  }
}