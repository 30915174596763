@font-face {
  font-family: "videodub-font";
  src: local("sui_generis"), url(/fonts/sui_generis.ttf);
}

#main {
  background-color: #efefef;
  height: 100%;

  #user-actions-container {
    display: flex;
    flex-direction: row-reverse;
    height: 80px;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100vw;

    #user-actions-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;

      #user-actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .user-action {
          display: flex;
          flex-direction: row;
          margin: 0 10px;

          .user-action-header {
            font-size: 15px;
            font-weight: bold;
          }

          .user-action-icon {
            height: 20px;
            margin-left: 10px;
            width: 20px;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
    }
  }

  #body {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    justify-content: space-between;
    width: 100vw;

    #top-section {
      height: 90%;

      #intro {
        display: flex;
        flex-direction: column;
        height: 20%;
        justify-content: space-around;
        padding-top: 30px;

        #intro-header {
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 20px;
          text-align: center;
        }

        #mini-headers {
          .mini-header {
            font-size: 20px;
            margin: 10px;
            text-align: center;
          }
        }
      }

      #inputs {
        margin: 0 auto;
        width: 540px;

        #inputs-container {
          height: 80%;

          #socialmedia-icons {
            background-color: white;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            height: 40px;
            justify-content: space-around;
            margin: 5px auto;
            width: 300px;

            #icons-row {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              width: 100%;
            }

            .socialmedia-icon {
              height: 20px;
              margin: 10px 0;
              width: 20px;

              img {
                height: 100%;
                width: 100%;
              }
            }
          }

          #enter-youtube {
            background-color: white;
            border-radius: 15px;
            box-shadow: 0px 0px 10px lightgrey;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            width: calc(100% - 20px);

            #youtube-input {
              border-width: 0px;
              color: black;
              font-family: "Ubuntu", sans-serif;
              font-size: 15px;
              outline: none;
              width: 90%;
            }

            #youtube-search {
              border-radius: 10px;
              box-shadow: 0px 0px 10px lightgrey;
              display: flex;
              flex-direction: row;
              height: 15px;
              padding: 5px;
              width: 15px;

              &:hover {
                box-shadow: 0px 0px 5px black;
                cursor: pointer;
              }
            }
          }

          #dragdrop-video {
            background-color: rgba(127, 127, 127, 0.05);
            border-color: lightgrey;
            border-radius: 5px;
            border-style: solid;
            border-width: 2px;
            color: darkgrey;
            display: flex;
            flex-direction: column;
            height: 200px;
            justify-content: space-around;
            margin: 20px auto;
            text-align: center;
            width: 500px;

            #dragdrop-video-row {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              margin: 0 auto;

              #dragdrop-video-header {
                font-size: 20px;
              }
            }

            &:hover {
              cursor: pointer;
              opacity: 0.5;
            }
          }
        }
      }

      #sample-video {
        height: 438px;
        margin: 10px auto;
        width: 700px;

        video {
          outline: none;
        }
      }

      #getting-video-loading {
        margin: 30px auto;
        width: 400px;

        #video-loading-header {
          font-size: 15px;
          text-align: center;
          width: 100%;
        }
      }

      #main-container {
        height: 100%;
        margin: 0 auto;
        width: 80%;

        #playlist {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-around;
          width: 100%;
        
          #container {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-around;
            width: 100%;

            #translated-header {
              font-family: "Ubuntu", sans-serif;
              font-size: 2.5vh;
              font-weight: bold;
              padding: 2vh 0;
              text-align: center;
            }
        
            #holder {
              display: flex;
              flex-direction: row;
              height: 80%;
              justify-content: space-around;
    
              video {
                height: 100%;
              }
            }
          }
        }

        #uploaded-video-container {
          display: flex;
          flex-direction: row;
          height: 80%;
          justify-content: space-around;
          padding-top: 20px;
          width: 100%;

          #settings {
            width: 30%;

            #settings-header {
              font-family: "Ubuntu", sans-serif;
              font-size: 2vh;
              font-weight: bold;
              margin-bottom: 5px;
              text-align: center;
            }

            #settings-container {
              background-color: white;
              border-radius: 20px;
              margin: 0 auto;
              padding: 10px;
              width: calc(100% - 20px);

              #selections {
                .selection {
                  background-color: white;
                  border-radius: 10px;
                  margin-bottom: 20px;

                  .selection-container {
                    display: flex;
                    flex-direction: row;
                    padding: 10px;

                    .selection-button,
                    .selection-button-disabled {
                      border-radius: 10px;
                      box-shadow: 0px 0px 10px lightgrey;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-around;
                      padding: 5px 10px;

                      .selection-header-header {
                        font-size: 13px;
                      }

                      .selection-arrow {
                        height: 20px;
                        margin-left: 10px;
                        width: 20px;
                      }

                      &:not([class$="-disabled"]):hover {
                        box-shadow: 0px 0px 5px lightgrey;
                        cursor: pointer;
                      }
                    }

                    .selection-edit,
                    .selection-edit-disabled {
                      border-radius: 15px;
                      border-style: solid;
                      border-width: 0.5px;
                      height: 20px;
                      margin-left: 10px;
                      padding: 5px;
                      width: 20px;

                      &:not([class$="-disabled"]):hover {
                        cursor: pointer;
                        opacity: 0.5;
                      }
                    }

                    .selection-items {
                      background-color: white;
                      border-radius: 10px;
                      box-shadow: 0px 0px 10px lightgrey;
                      max-height: 200px;
                      margin-top: 35px;
                      overflow-y: scroll;
                      position: absolute;

                      #search-input {
                        border-color: black;
                        border-radius: 10px;
                        border-style: solid;
                        border-width: 0.5px;
                        margin: 5px;
                        outline: none;
                        padding: 7px;
                        width: 200px;
                      }

                      #selection-items-list {
                        .selection-item {
                          display: flex;
                          flex-direction: row;
                          padding: 10px;

                          .selection-item-point {
                            border-radius: 10px;
                            border-style: solid;
                            border-width: 0.5;
                            height: 8px;
                            margin: 5px 0;
                            width: 8px;
                          }

                          .selection-item-icon {
                            height: 30px;
                            width: 30px;
                          }

                          .selection-item-header {
                            font-size: 13px;
                            font-weight: bold;
                            margin-left: 10px;
                          }

                          &:hover {
                            background-color: lightgrey;
                            cursor: pointer;
                          }
                        }
                      }
                    }
                  }

                  .selection-radios {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    margin: 10px auto;
                    width: 50%;
                  }
                }
              }

              #translate-button,
              #translate-button-disabled {
                background-color: black;
                border-radius: 10px;
                box-shadow: 0px 0px 10px black;
                color: white;
                font-family: "Ubuntu", sans-serif;
                margin: 40px auto 10px auto;
                padding: 10px;
                text-align: center;
                width: 50%;

                &:not([id$="-disabled"]):hover {
                  cursor: pointer;
                  opacity: 0.5;
                }
              }
            }
          }

          #video-container {
            border-radius: 30px;
            height: 85%;
            margin: 0 2.5%;
            width: 65%;

            #video-container-holder {
              display: flex;
              flex-direction: row;
              height: 100%;
              justify-content: space-around;

              video {
                height: 100%;
              }
            }

            #video-placeholder {
              align-items: center;
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.05);
              display: flex;
              flex-direction: column;
              height: 50vh;
              margin: 0 2.5%;
              justify-content: space-around;
              width: 95%;
            }

            #control-section {
              background-color: white;
              border-radius: 10px;
              font-size: 15px;
              margin: 5px auto 0 auto;
              padding: 2vh 0;
              width: 95%;

              #progress-status {
                font-family: "Ubuntu", sans-serif;
                text-align: center;

                #status-header-row {
                  display: flex;
                  flex-direction: row;

                  #translating-loading {
                    animation: loading 1s linear infinite;
                    height: 15px;
                    margin-left: 10px;
                    width: 15px;
                  }

                  @keyframes loading {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }

                #translating-header {
                  font-weight: bold;
                }
              }

              #video-cutted-header {
                font-weight: bold;
                text-align: center;
              }

              #bottom-options {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;

                #video-trim-container {
                  width: 50%;
        
                  #video-trim-actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;

                    .video-trim-action {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      margin-top: 2vh;
                      width: 49%;

                      .video-trim-left {
                        text-align: center;
                        width: 90%;

                        .video-trim-left-header {
                          font-size: 15px;
                          font-weight: bold;
                        }

                        .video-trim-left-mini-header {
                          font-size: 10px;
                        }

                        .video-trim-control-container {
                          display: flex;
                          flex-direction: row;
                          margin: 0 auto;
                          width: 50%;

                          .video-trim-left-input {
                            text-align: center;
                            width: 50px;
                          }
                        }
                      }

                      .video-trim-right {
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;

                        .trim-control-container {
                          display: flex;
                          flex-direction: column;
                          justify-content: space-around;
                          width: 20%;
                    
                          .trim-control {
                            height: 2vh;
                            width: 2vh;
                          }
                        }
                      }
                    }
                  }
                }

                #video-actions-column {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;

                  #video-actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;

                    .video-action,
                    .video-action-disabled {
                      background-color: white;
                      border-radius: 10px;
                      box-shadow: 0px 0px 5px lightgrey;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-around;
                      padding: 10px;

                      .video-action-icon {
                        margin-left: 10px;
                      }
                    }

                    .video-action:hover {
                      cursor: pointer;
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
          }
        }

        #translated-video {
          height: 80%;
          margin: 0 auto;
          width: 90%;

          #translated-video-container {
            display: flex;
            flex-direction: row;
            height: 90%;
            justify-content: space-around;

            video {
              height: 100%;
            }
          }
        }

        #translated-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 2vh auto 0 auto;
          width: 80%;

          .translated-action,
          .translated-action-disabled {
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 0px 5px lightgrey;
            display: flex;
            flex-direction: row;
            font-size: 1vh;
            height: 40px;
            justify-content: space-around;
            margin: 1%;
            padding: 0 1%;

            .translated-action-header {
              font-size: 13px;
              margin-right: 1%;
              text-align: center;
            }

            .translated-action-icon {
              height: 20px;
              width: 20px;
            }
          }

          .translated-action:hover {
            cursor: pointer;
            opacity: 0.5;
          }

          #share-video-container {
            background-color: rgba(0, 103, 199, 0.8);
            border-radius: 30px;
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            padding: 5px;
            width: 40%;
    
            #share-video-header {
              background-color: white;
              border-radius: 40px;
              font-size: 13px;
              padding: 10px;
              width: calc(80% - 20px);

              div {
                overflow: hidden;
                width: 100%;
              }
            }
    
            #share-video-button,
            #share-video-button-disabled {
              border-radius: 0 20px 20px 0;
              color: white;
              display: flex;
              flex-direction: column;
              font-size: 10px;
              justify-content: space-around;
              text-align: center;
              width: 20%;
    
              &:hover {
                cursor: pointer;
                font-weight: bold;
              }
            }
          }
        }

        #feedback-header {
          font-size: 12px;
          font-weight: bold;
          margin-top: 5px;
          text-align: center;
        }
      }
    }

    #footer {
      height: 8%;
      width: 100vw;
    }
  }

  #hidden {
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-around;
    position: absolute;
    top: 0;
    width: 100vw;

    #undone-box {
      background-color: white;
      margin: 0 auto;

      #undone-header {
        font-size: 20px;
        padding: 10px;
        text-align: center;
      }
    }

    #edit-target-langs {
      background-color: white;
      border-radius: 10px;
      border-style: solid;
      margin: 0 auto;
      width: 500px;

      #edit-target-langs-header {
        font-size: 20px;
        font-weight: bold;
        padding: 20px 0;
        text-align: center;
      }

      #edit-target-langs-list-header {
        font-size: 20px;
        text-align: center;
      }

      #edit-target-langs-list {
        border-radius: 10px;
        margin-bottom: 30px;

        #edit-target-langs-container {
          display: flex;
          flex-direction: row;
          margin: 20px auto;
          width: 300px;

          #edit-target-langs-button {
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 0px 10px lightgrey;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 10px;
            width: 280px;

            .select-tarlangs-arrow {
              height: 20px;
              margin-left: 10px;
              width: 20px;
            }

            &:hover {
              box-shadow: 0px 0px 5px lightgrey;
              cursor: pointer;
            }
          }

          .select-tarlangs-items {
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 0px 10px lightgrey;
            margin: 45px 0 0 0;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            width: 300px;

            #search-input {
              border-color: black;
              border-radius: 10px;
              border-style: solid;
              border-width: 0.5px;
              margin: 5px;
              outline: none;
              padding: 7px;
              width: calc(100% - 24px);
            }

            #edit-target-langs-items-list {
              height: 200px;
              overflow-y: scroll;

              .select-tarlangs-item {
                display: flex;
                flex-direction: row;
                padding: 5px;

                .select-tarlangs-item-point {
                  border-radius: 10px;
                  border-style: solid;
                  border-width: 0.5;
                  height: 8px;
                  margin: 5px 0;
                  width: 8px;
                }

                .select-tarlangs-item-icon {
                  height: 30px;
                  width: 30px;
                }

                .select-tarlangs-item-header {
                  font-size: 15px;
                  font-weight: bold;
                  margin-left: 10px;
                }

                &:hover {
                  background-color: lightgrey;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      #selected-langs-header {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;
        text-align: center;
      }

      #selected-langs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .selected-lang {
          background-color: white;
          border-radius: 10px;
          box-shadow: 0px 0px 5px lightgrey;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 10px;
          padding: 10px;

          .selected-lang-header {
            font-size: 15px;
          }

          .selected-lang-delete {
            height: 15px;
            width: 15px;
          }
        }
      }

      #edit-target-langs-save {
        background-color: black;
        border-radius: 10px;
        box-shadow: 0px 0px 10px black;
        color: white;
        font-family: "Ubuntu", sans-serif;
        margin: 20px auto;
        padding: 10px;
        text-align: center;
        width: 200px;

        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }

      }




    }
  }

  .errormsg {
    color: red;
    text-align: center;
  }

  #loading-icon {
    animation: rotation 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}