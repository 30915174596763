#register {
  background-color: #efefef;
  height: 100vh;
  width: 100vw;

  #headers {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 80px;
    width: 100%;

    #logo {
      height: 50px;
      width: 317px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  #body {
    display: flex;
    flex-direction: column;
    height: calc(90% - 80px);
    justify-content: space-around;
    width: 100%;

    #form {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 5px lightgrey;
      flex-direction: column;
      justify-content: space-around;
      margin: 0 auto;
      padding: 10px;
      width: 500px;

      #note {
        background-color: rgba(0, 147, 255, 0.1);
        border-color: #D3EAFF;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px white;
        color: #00529E;
        font-weight: 500;
        padding: 2vh;

        &:hover {
          cursor: pointer;
        }
      }

      #form-header {
        font-size: 3vh;
        font-weight: bold;
        margin: 2vh 0;
        text-align: center;
      }

      #signups-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 5px 0;
        width: 100%;

        #signups {
          .thirdparty-signup {
            background-color: lightgrey;
            border-radius: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 5px 0;
            padding: 10px;

            .thirdparty-signup-icon {
              height: 30px;
              margin: 5px;
              width: 30px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .thirdparty-signup-header {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
              width: 100%;
            }

            &:hover {
              cursor: pointer;
              opacity: 0.5;
            }
          }

          #thirdparty-header {
            padding: 30px 0;
          }

          #email-login {
            background-color: lightgrey;
            border-radius: 30px;
            margin: 5px 0;
            padding: 10px;

            #email-login-header {
              text-align: center;
            }

            &:hover {
              cursor: pointer;
              opacity: 0.5;
            }
          }
        }
      }

      #thirdparty-header {
        font-weight: 500;
        text-align: center;
      }

      #instruction-header {
        padding: 2vh 0;
      }

      #inputs {
        width: 100%;

        .input-container {
          margin-bottom: 2vh;
          width: 100%;

          .input-header {
            font-weight: bold;
            margin-bottom: 1vh;
          }

          .input-value {
            input {
              background-color: rgba(0, 147, 255, 0.05);
              border-color: lightgrey;
              border-style: solid;
              border-width: 0.5px;
              border-radius: 5px;
              box-shadow: 0px 0px 5px white;
              font-size: 2vh;
              padding: 1vh;
              width: calc(100% - 4vh);
            }
          }
        }
      }

      #resetpassword-button {
        color: darkgrey;
        font-size: 2vh;
        padding: 2vh 0;

        a {
          color: #00529E;
          text-decoration: none;
        }
      }

      #errormsg {
        color: red;
        font-size: 2vh;
        margin-bottom: 1vh;
      }

      #form-actions {
        display: flex;
        flex-direction: row;
      }

      .form-action {
        background-color: rgb(0, 130, 199);
        border-color: darkblue;
        border-radius: 5px;
        border-style: solid;
        border-width: 0.5px;
        color: white;
        font-weight: bold;
        padding: 1vh;
        text-align: center;
        width: 10vh;

        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}