#landing {
  background-color: #efefef;
  height: 100vh;
  width: 100vw;

  #header {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 80px;
    width: 100%;

    #header-icon {
      height: 50px;
      width: 317px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  #user-actions-container {
    display: flex;
    flex-direction: row-reverse;
    height: 80px;
    justify-content: space-between;
    position: absolute;
    right: 0;
    top: 0;

    #user-actions-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      margin-right: 10px;
    }
  }

  #user-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .user-action {
      background-color: black;
      border-color: black;
      border-radius: 10px;
      border-style: solid;
      border-width: 1px;
      color: white;
      display: flex;
      flex-direction: row;
      font-size: 20px;
      font-weight: bold;
      justify-content: space-around;
      padding: 10px;
      text-align: center;

      .user-action-header {
        font-size: 15px;
        font-weight: bold;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }

  #intro {
    display: flex;
    flex-direction: column;
    font-family: "Ubuntu", sans-serif;
    height: calc(90% - 60px);
    justify-content: space-between;
    margin: 0 auto;

    #top-section {
      display: flex;
      flex-direction: column;
      height: 30%;
      justify-content: space-around;
      width: 100%;

      #header-title {
        font-size: 2vw;
        font-weight: bold;
        padding: 0 20%;
        text-align: center;
      }

      #header-mini-title {
        font-size: 1.2vw;
        font-weight: 200;
        margin: 20px auto;
        text-align: center;
        width: 70%;
      }
    }

    #bottom-section {
      height: 70%;
      width: 100%;

      #samples-row {
        display: flex;
        flex-direction: row;
        height: 80%;
        justify-content: space-around;
        width: 100%;

        #samples {
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: space-around;

          .sample {
            height: 100%;
            margin: 0 5px;

            .sample-header {
              font-size: 20px;
              font-weight: bold;
              height: 8vh;
              margin-bottom: 1%;
              text-align: center;
            }

            .select-tarlangs {
              border-radius: 10px;
              height: 8vh;
              margin-bottom: 1%;

              .select-tarlangs-container {
                display: flex;
                flex-direction: row;
                margin: 0 auto;
                padding: 0 10px;
                width: 350px;

                .select-tarlangs-button {
                  background-color: white;
                  border-radius: 10px;
                  box-shadow: 0px 0px 10px lightgrey;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                  padding: 10px;
                  width: 100%;

                  .select-tarlangs-arrow {
                    height: 20px;
                    margin-left: 10px;
                    width: 20px;
                  }

                  &:hover {
                    box-shadow: 0px 0px 5px lightgrey;
                    cursor: pointer;
                  }
                }

                .select-tarlangs-items {
                  background-color: white;
                  border-radius: 10px;
                  box-shadow: 0px 0px 10px lightgrey;
                  margin-top: 45px;
                  overflow: hidden;
                  position: absolute;
                  z-index: 1;
                  width: 350px;

                  #search-input {
                    border-color: black;
                    border-radius: 5px;
                    border-style: solid;
                    border-width: 2px;
                    margin: 5px;
                    outline: none;
                    padding: 7px;
                    width: calc(100% - 28px);
                  }

                  #select-tarlangs-items-list {
                    height: 300px;
                    overflow-y: scroll;

                    .select-tarlangs-item,
                    .select-tarlangs-item-selected {
                      display: flex;
                      flex-direction: row;
                      padding: 10px;

                      .select-tarlangs-item-point {
                        border-radius: 10px;
                        border-style: solid;
                        border-width: 0.5;
                        height: 8px;
                        margin: 5px 0;
                        width: 8px;
                      }

                      .select-tarlangs-item-icon {
                        height: 30px;
                        width: 30px;
                      }

                      .select-tarlangs-item-header {
                        font-weight: bold;
                        margin-left: 10px;
                      }

                      &:hover {
                        background-color: lightgrey;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }

            .sample-video {
              height: 80%;

              video {
                height: 100%;
              }
            }

            #loading-container {
              align-items: center;
              display: flex;
              height: 100%;
              justify-content: center;
              width: 100%;

              #loading {
                animation: rotation 1s linear infinite;
                height: 30px;
                width: 30px;
              }
            }

            @keyframes rotation {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }

      #translate-button-container {
        display: flex;
        height: 20%;
        justify-content: center;
        width: 100%;

        #translate-button {
          background-color: rgba(0, 103, 199, 0.8);
          border-color: black;
          border-radius: 10px;
          border-style: solid;
          border-width: 1px;
          color: white;
          display: flex;
          flex-direction: column;
          font-size: 20px;
          font-weight: bold;
          height: 40%;
          justify-content: space-around;
          margin: 10px auto 0 auto;
          text-align: center;
          width: 200px;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
  }
}