#email {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
  width: 100vw;

  #container {
    background-color: white;
    box-shadow: 0px 0px 5px lightgrey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 500px;

    #logo {
      margin: 30px auto;
      width: 50%;

      img {
        width: 100%;
      }
    }

    #verify-icon {
      height: 100px;
      margin: 0 auto;
      width: 100px;
    }

    #header {
      font-size: 30px;
      font-weight: bold;
      margin: 20px 0;
      text-align: center;
    }

    #mini-header {
      color: #5D5D5D;
      font-size: 15px;
      margin: 20px 0;
      padding: 0 10%;
      text-align: center;
    }

    #login-button {
      background-color: lightblue;
      border-radius: 20px;
      color: white;
      font-weight: bold;
      margin: 20px auto 20px auto;
      padding: 10px 0;
      text-align: center;
      width: 300px;

      &:hover {
        background-color: blue;
        cursor: pointer;
      }
    }
  }
}