#footer-comp {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-around;
  width: 100%;

  #footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    width: 50%;

    #co-logo {
      width: 150px;

      img {
        width: 100%;
      }
    }

    #co-header {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 500;
      justify-content: space-around;
    }
  }

  #footer-header {
    font-size: 8px;
    text-align: center;
  }
}