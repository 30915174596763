#playsource {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  width: 100vw;

  #container {
    display: flex;
    flex-direction: column;
    height: calc(90% - 80px);
    justify-content: space-around;

    #playsource-video {
      height: 90%;
      margin: 0 auto;
      width: 100%;

      #playsource-header {
        font-size: 3vh;
        font-weight: bold;
        padding: 2vh 0;
        text-align: center;
      }

      #holder-row {
        height: 80%;

        #holder {
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: space-around;

          video {
            height: 100%;
          }
        }
      }
    }

    #loading {
      display: flex;
      height: 80%;
      justify-content: center;
      margin: 0 auto;
      width: 100%;

      #loading-header {
        font-size: 2vh;
        font-weight: bold;
        text-align: center;
      }

      #loading-icon {
        animation: rotation 1s linear infinite;
        width: 28px;
        margin: 20px auto;
        height: 28px;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
