#playtranslated {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100vw;

  #container {
    display: flex;
    flex-direction: column;
    height: calc(90% - 80px);
    justify-content: space-around;

    #playtranslated-video {
      height: 90%;
      margin: 0 auto;
      width: 100%;

      #playtranslated-header {
        font-size: 3vh;
        font-weight: bold;
        padding: 2vh 0;
        text-align: center;
      }

      #holder-row {
        height: 60vh;

        #holder {
          display: flex;
          flex-direction: row;
          height: 100%;
          justify-content: space-around;
          margin: 0 auto;
          width: 60vw;

          video {
            height: 100%;
          }
        }
      }

      #bottom-section-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;

        #bottom-section {
          display: flex;
          flex-direction: row;
          height: 50px;
          justify-content: space-around;
          margin-top: 10px;
          width: 50%;

          #bottom-section-container {
            background-color: rgba(0, 103, 199, 0.8);
            border-radius: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 5px;
            width: calc(50% - 10px);

            #bottom-section-header {
              background-color: white;
              border-radius: 20px 0 0 20px;
              font-size: 13px;
              padding: 10px;
              width: calc(80% - 20px);

              div {
                overflow: hidden;
                width: 100%;
              }
            }

            #bottom-section-button {
              border-radius: 0 20px 20px 0;
              color: white;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              text-align: center;
              width: 20%;

              &:hover {
                cursor: pointer;
                font-weight: bold;
              }
            }
          }

          .bottom-section-action {
            background-color: rgba(0, 103, 199, 0.8);
            border-color: black;
            border-radius: 10px;
            border-style: solid;
            border-width: 2px;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 5px 10px;
            text-align: center;
            width: calc(20% - 24px);

            &:hover {
              cursor: pointer;
              opacity: 0.5;
            }
          }
        }
      }
    }

    #loading {
      display: flex;
      height: 80%;
      justify-content: center;
      margin: 0 auto;
      width: 100%;

      #loading-header {
        font-size: 2vh;
        font-weight: bold;
        text-align: center;
      }

      #loading-icon {
        animation: rotation 1s linear infinite;
        width: 28px;
        margin: 20px auto;
        height: 28px;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    #feedback {
      border-width: 0;
      margin: 20px auto;
      width: 50vw;

      iframe {
        height: 100vh;
        width: 100%;
      }
    }
  }
}
