#verify {
  height: 100vh;
  width: 100vw;

  #body {
    height: calc(90% - 80px);

    #verify-user {
      height: 100px;
      margin: 0 auto;
      width: 100px;
    }

    #verify-header {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }
  }
}
